import './annualSubscription.css';
import gbgc_form_img from '../../assets/images/gbgc_form_img.png';
import { signatureContext } from "../../context/context";
import { useContext } from 'react';
 
const AnnualSubscription = (props) => {
    const {primaryDetails, adminDetails} = props;
    
    const signatureImg = useContext(signatureContext)

    return (
        <>
            <div className="app-body margin-tb60">
                <div className="page">
                    <h3 className="center header-title margin-t5"><strong>THE GAEKWAD BARODA GOLF CLUB</strong></h3>
                    <hr className="cut-hr" />
                    <h3 className="center header-subtitle margin-t5"><strong>Annual Subscription Plans for 2023-24</strong></h3>
                    <hr className="cut-hr" />

                    <div className="member-info">
                        <table>
                            <tbody>
                                <tr>
                                    <td width={"25%"}><strong>MEMBER NAME:</strong></td>
                                    <td>{primaryDetails?.FirstName && primaryDetails?.LastName ?  primaryDetails.FirstName + ' ' + primaryDetails.LastName : ''}</td>
                                    <td rowSpan="2" className='text-right'>
                                        <img src={gbgc_form_img} alt="Member Photo" style={{ width: '100px', height: '100px' }} />
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>MEMBERSHIP NO.:</strong></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table className="subscription-table">
                        <thead> 
                            <tr>
                                <th>Sr. No</th>
                                <th>Code #</th>
                                <th>Subscription Plans #</th>
                                <th>Total Subsc. Amount in INR</th>
                                <th>Please select your plan</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontWeight: '600'}}>
                            <tr>
                                <td>1</td>
                                <td>GPP</td>
                                <td>GOLF PREMIUM PLAN</td>
                                <td>65,000</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>FCP</td>
                                <td>FAMILY COMBO PLAN</td>
                                <td>50,000</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>FSRSP</td>
                                <td>FAMILY SWIM &amp; RAQUET SPORTS PLAN</td>
                                <td>26,000</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>ASF</td>
                                <td>ANNUAL SPORTS FEE</td>
                                <td>7,500</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>AFCP</td>
                                <td>ASSOCIATE FAMILY COMBO PLAN</td>
                                <td>20,000</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>AFSRSP</td>
                                <td>ASSOCIATE FAMILY SWIM &amp; RAQUET SPORTS PLAN</td>
                                <td>10,400</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <hr className="cut-hr" />
                    <ol className="subscription-notes">
                        <li>All stated charges are inclusive of 18% GST.</li>
                        <li>All plans are for a period from 1<sup>st</sup> April 2023 to 31<sup>st</sup> March 2024.</li>
                        <li>Member's whose membership is due for renewal should first renew the membership and then subscribe to the plan.</li>
                        <li>Only golf memberships can subscribe to golf plans while members with club membership can subscribe to other plans.</li>
                        <li>It is Mandatory for every member to subscribe to any ONE plan. Please mark (X) in the selected plan column.</li>
                        <li>Any member failing to subscribe to any plan by 31 May 2023 will be put under default "ASF-2 Plan."</li>
                        <li>Golf Premium Plan has been introduced for golfers specially who wish to support the expansion of the golf course to 18 holes. Under this plan, all FCP-I facilities are available.</li>
                        <li>Pro-shop vouchers cannot be en-cashed against any of the annual subscription plans.</li>
                        <li>Payment for all plans to be done through the app or web portal gateway or send a crossed cheque in favor of "Gaekwad Baroda Golf Club."</li>
                        <li>All Members will be billed a monthly subscription of INR 175/- per month plus applicable GST.</li>
                    </ol>
                    <hr className="cut-hr" />

                    <div className="footer-annual-sub">
                        <div className="footer-left-annual-sub">
                            <p><strong>FORM PREPARED BY: JESSY POTHEN</strong></p>
                            <p><strong>DESPATCHED THROUGH EMAIL</strong></p>
                            <p><strong>DATE:</strong> { adminDetails?.AccountDate && moment(adminDetails.AccountDate).format('DD-MM-YYYY')}</p>
                        </div>
                        <div className="footer-right-annual-sub">
                            {(!!signatureImg) ? (
                            <div className="avatar__holder"
                                style={{
                                    backgroundImage: typeof signatureImg === 'object' && signatureImg instanceof Blob
                                        ? `url(${URL.createObjectURL(signatureImg)})`
                                        : `url(${signatureImg})`,
                                    width: "130px",
                                    height: "30px",
                                    marginLeft: '175px',
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                            ></div>
                            ) : (
                                <div style={{ width: "130px", height: "30px"}}></div>
                            )}                             
                            {/* {(signatureImg) ? 
                                <div className="signature-text" style={{ width: "130px", height: "35px", border: '1px solid #ddd',borderRadius: '4px',display: 'flex',alignItems: 'center',justifyContent: 'center',textAlign: 'center',overflow: 'hidden',fontSize: '1rem',marginLeft: '186px'}}>
                                    <strong>
                                        {signatureImg}
                                    </strong>
                                </div>                                 
                            : <div style={{ width: "130px", height: "30px"}}></div>} */}
                            <p><strong>SIGNATURE OF MEMBER:</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  );
};

export default AnnualSubscription;
