import React, { useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignatureModal = (props) => {
    const { setSignaturePhotoBlob, openSignatureCropper } = props;
    const sigPad = useRef(null);

    // Open the modal and handle dismiss
    useEffect(() => {
        $('#modalSignature').modal({
            backdrop: 'static',
        });
        $('#modalSignature').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        }); 
    }, []);

    function base64ToBlob(base64, type = 'image/jpeg') {
        // Remove the prefix (if it exists)
        const byteCharacters = atob(base64.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        
        // Create a Uint8Array to hold the byte values
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        // Create a Blob from the Uint8Array
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: type });
    }

    // Save signature
    const saveSignature = (e) => {
        e.preventDefault();
        if (sigPad.current) {
            // Get the signature data in PNG format
            const pngData = sigPad.current.toDataURL('image/png');

            // Convert the base64 PNG data to a Blob
            const blob = base64ToBlob(pngData);

            // Set the Blob for use in parent component
            setSignaturePhotoBlob(blob);

            // Close the modal
            $('#modalSignature').modal('hide');
        }
    };
     
    // Clear signature pad
    const clearSignature = (e) => {
        e.preventDefault();
        if (sigPad.current) {
            sigPad.current.clear(); // Clear the signature pad
        }
    };
  
    return (
        <div id="modalSignature" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header padding-b0">
                        <button type="button" className="close" onClick={() => $('#modalSignature').modal('hide')}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="signature-container" style={{ height: '300px', width: '100%', textAlign: 'center', backgroundColor: '#b7b7b74d', padding: '25px'}}>
                            <SignatureCanvas
                                ref={sigPad}
                                penColor='black'
                                backgroundColor='white'
                                canvasProps={{ 
                                    width: 700, 
                                    height: 240, 
                                    style: { 
                                        border: '2px dotted grey',  // Dotted border
                                    } 
                                }} 
                                minWidth={1}
                                maxWidth={3}
                                throttle={16} // Smoother lines
                            />
                            
                        </div>
                        <h5 className="modal-title" style={{textAlign: 'Center'}}>Sign within the dotted lines</h5>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-default upload-button margin-r10"  onClick={openSignatureCropper}>Upload Signature</button>
                        <button className="btn  btn-default margin-r10" onClick={clearSignature}>Clear</button>
                        <button className="btn btn-primary" onClick={saveSignature}>Save Signature</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignatureModal;
