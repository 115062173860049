const htmlContent1 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */;
    font-size: large;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<div class="header">
  <h5><strong>TERMS & CONDITIONS</strong></h5>
</div>

<ol>
  <li>
    <p>GAEKWAD BARODA GOLF CLUB (hereinafter referred to as “GBGC”) having its office at Motibaug Palace, Gate # 3, L.V.Palace Estate, J.N.Marg, Baroda – 390 001 (hereinafter referred to as “GBGC”), is owned and run by Gaekwar Enterprise Private Ltd., CIN No. U29199GJ1995PTC026831 a company incorporated under Companies Act, 2013 having registered office at The Motibaug Palace, Gate no.3, L.V.Palace Estate, J.N.Marg, Baroda – 390 001 (hereinafter referred to as “GEPL”).</p>
  </li>
  
  <li>
    <p>The application for the membership to the GBGC (“Membership”) may be made as per the prescribed format (“Application Form”) that forms a part of the Membership Terms and Conditions (“Terms and Conditions”). The Membership is subject to the Terms and Conditions, the rules and regulations made there under as amended from time to time and as notified to the members by putting up a copy thereof at the Clubhouse or any other suitable place (“Rules and Regulations”). The terms and Conditions include the FEE STRUCTURE for the membership as of the date thereof and as amended from time to time by the Management. PLAN & FEE STRUCTURE ARE ALSO PART OF THIS AGREEMENT.</p>
  </li>
  
  <li>
    <p>GBGC is a proprietary club and belongs to GEPL.</p>
  </li>
  
  <li>
    <p>The management of the GBGC vests exclusively in the Board of Directors of GEPL and its authorized executives and who in these Rules and Regulations are referred to as "Management". The Management reserves the right to reject or to conditionally accept the Application Form without assigning any reason whatsoever. A member ("Member") is a person whose Application has been accepted by the Management.</p>
  </li>
  
  <li>
    <p>Members have the right to use the facilities provided by the GBGC on the days and during the time as specified by the Management. The Management may further designate areas as Clubhouse or Golf Course, Practice Range, Tennis Courts, Badminton Courts, Swimming pool & Gym.</p>
  </li>
  
  <li>
    <p>CATEGORIES OF MEMBERSHIP: The Membership to GBGC is open to the following categories</p>
    <ul style="list-style-type: none;margin-left: -42px;">
      <li>A)  INDIVIDUAL MEMBER</li>
        <ul style="list-style-type: none;">
          <li>i) Permanent residents of India</li>
          <li>ii) Non-Resident Indians (NRI)</li>
          <li>iii) Foreign nationals, residing in or visiting India i.e., expatriates</li>
        </ul>
        <br/>
      <li>B)  CORPORATE MEMBER</li>
        <p>The Corporate Members shall be Companies incorporated in India or abroad, partnership firms or any other body incorporated under any national jurisdiction. Such Corporate Members may nominate their directors, partners or employees, as the case may be ("Corporate Nominee"), to use the facilities of GBGC, subject to the Terms and Conditions and the Rules and Regulations made in this regard. A Corporate Member may request for more than one nomination for a period of time and the fee as specified in the Fee Structure attached there under.</p>
    </ul>
  </li>
</ol>

</body>
</html>`;
const htmlContent2 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="7">
  <li>
    <p>SUB CATEGORIES: {Individual & Corporate Membership}</p>
    <p>The club is offering two sub categories of membership as below.</p>
    <ol style="list-style-type: none;padding-left: 0px;">
      <li>A) GOLF MEMBERSHIP:</li>
        <ul style="list-style-type: none;">
          <li>ALL SPORTS INCLUDING GOLF. This membership entitles the applicant to use all sports facilities including Golf course/Practice Range.</li>
        </ul>
      <br/>
      <li>B) CLUB MEMBERSHIP (WITHOUT GOLF)</li>
        <ul style="list-style-type: none;">
          <li>a) ALL SPORTS EXCLUDING GOLF. This membership entitles the applicant to use all sports except Golf (Golf Course/Practice Range).</li>
          <li>b) A CLUB MEMBERSHIP can be switched to a GOLF MEMBERSHIP by paying the difference of applicable fee for the duration of his/her membership tenure on a pro-rata basis. However a GOLF MEMBERSHIP cannot be switched to a CLUB MEMBERSHIP. After a switch from CLUB MEMBERSHIP to GOLF MEMBERSHIP, a RE-SWITCH will not be permitted. This membership SWITCH is subject to the approval of the management. Further no refunds will be payable either.</li>
        </ul>
      <br/>
      <li>C) ANNUAL MEMBERSHIP</li>
        <ul style="list-style-type: none;">
          <li>i) Annual Individual with Golf Plan - This agreement is for Golf Membership</li>
        </ul>
    </ol>
  </li>

  <li>
    <p>ELIGIBILITY:</p>
    <ul style="list-style-type: none;padding-left: 0px;">
      <li>A) Persons indicated in CLAUSE 6 here in above can offer their application for membership as per the details in clause 9 herein below.</li>
      <li>B) No Individual / Corporate Member who has been declared to be an un-discharged insolvent or has been convicted by a court shall be eligible for the Membership or the nomination to the Golf Resort.</li>
      <li>C) A Corporate shall be eligible to nominate a fixed number of nominees for a period of time as provided for in the Fee Structure.</li>
    </ul>
    <br/>
  </li>
  
  <li>
    <p>PROCEDURE:</p>
    <p>Individuals and Corporate seeking Membership are required to submit the duly filled in Application Form complete in all respects. Management of the GBGC has the absolute right to accept or reject any application for Membership at their sole discretion without assigning any reason whatsoever and the applicant shall have no right to challenge the decision of the Management. Membership is awarded only after proper & complete procedure is followed as decided by management.</p>
  </li>
  
  <li>
    <p>TENURE:</p>
    <p>A) THIS MEMBERSHIP availed by you is for a fixed tenure of 3/5 years (36 / 60 Months) from the date of membership accepted by the management.</p>
  </li>
</ol>

</body>
</html>
`;
const htmlContent3 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  table {
    border-collapse: collapse;
    width: 80%;
  }
  th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  th {
    background-color: #f2f2f2;
  }
</style>
</head>
<body>
<ol start="11">
    <p>B) ANNUAL MEMBERSHIP is for a fixed tenure of 1 Year (12 Months/365 Days) from the DATE OF SIGNING as accepted by the management.</p>

  <li>
    <p>MEMBERSHIP FEES:</p>
    <p style="margin-bottom: 0px;" >A) APPLICANTS ARE REQUIRED TO PAY</p>
    <ol style="list-style-type: none;">
      <li>1) A onetime MEMBERSHIP ENTRANCE FEE ("Entrance Fee"), non-refundable, as prescribed in the Fee Structure; and / or a ONE TIME INTEREST FREE (security deposit) as prescribed in the Fee Structure, refundable only after the expiry of term of Membership or on the surrender of Membership and subject to a lock period as mentioned in clause 11(B) and;</li>
      <li>2) A monthly and/or annual recurring fee ("Monthly/Annual Fee") as prescribed in the Fee Structure may be charged from time to time. (Not Applicable to Annual Membership)</li>
      <li>3) An annual or monthly subscription charge for various facilities/sports that would be offered from time to time by the management would be charged extra. Annual Sports Fee has to be selected & paid in first quarter of financial year or else, it will attract non subscription charges for another quarter and lead to temporary discontinuation of facility usage for you & your dependents.</li>
      <li>4) GST: Applicants are required to pay the prevailing GST or any other tax as specified in the Finance Bill of Government of India wherever applicable.</li>
    </ol>
  </li>

    <p style="margin-bottom: 0px;" >B) REFUND OF INTEREST FREE SECURITY DEPOSIT:</p>
    <ol style="list-style-type: none;">
      <li>1) The INTEREST FREE SECURITY DEPOSIT made with the Club at the time of grant of the membership is refundable only after the expiry of term of membership (3 / 5 Years or 1 year) as the case may be, without payment of any interest or compensation of whatsoever nature after adjusting dues if any against the member. However, during the membership tenure, if the Management is satisfied that special circumstances justify the grant of premature refund, then the Club will refund only a part of the deposit in accordance with the following schedule and forfeit the balance.</li>
      <li>REFUND SCHEDULE OF PREMATURE REPAYMENT FOR INTEREST FREE DEPOSITS:</li>
      <center style="
      margin-top: 20px;
      margin-bottom: 20px;"
      >
      <table width="80%">
      <thead>
        <tr>
          <th>Tenure</th>
          <th>Lock-in period</th>
          <th>% of Interest Free Deposit to be Refunded</th>
        </tr>
      </thead>
      <tbody>
            <tr>
            <td>1 years</td>
            <td>After 1 year</td>
            <td>-</td>
            </tr>
            <tr>
            <td>3 years</td>
            <td>After 2 years</td>
            <td>40%</td>
            </tr>
            <tr>
            <td>5 years</td>
            <td>After 4 years</td>
            <td>40%</td>
            </tr>
        </tbody>
    </table>
    </center>

      <li>2) If the interest free security deposit received by the club is in installments and/or deferred manner then every such amount shall be refunded back in the similar tenure after expiry of 30 days.</li>
      <li>3) NON-PAYMENT OF MEMBERSHIP FEES: Should any applicant fail to pay the complete Entrance Fee amount or the Interest Free Security Deposit amount or any of the cheques bounce, then the club management is empowered to cancel / reject the membership and retain all the money. The decision will be completely with the club management</li>
    </ol>
</ol>

</body>
</html>

`;
const htmlContent4 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="12">
  <li>
    <p>MEMBERSHIP RIGHTS:</p>
    <p>A) NOMINATION BY CORPORATE MEMBERS:</p>
    <p style="margin-left: 15px;">A Corporate Member may choose one of the Membership options (term of the Membership and the number of nominees permitted) provided for under the Fee Structure, to avail the GBGC facilities. Nominations can be changed during the tenure of Membership by making an application to the Management along with a copy of the Resolution passed by the Board of Directors of the Company which is the concerned Corporate Member, or a request letter signed by all the partners of the firm which is a Corporate Member and upon payment of applicable re-nomination fee as may be fixed by the Management from time to time.</p>
    <p>B) ENTITLEMENT:</p>
    <p style="margin-left: 15px;">Subject to the Rules and Regulations made in this regard the GBGC facilities are available to spouses and the children of the Individual Members and the Corporate Nominees. Children for the purposes of this clause mean a girl/boy until she/he attains the age of Twenty four (24) years ("Eligible Children").</p>
    <p>C) GUESTS:</p>
    <p style="margin-left: 15px;">Guests of the members shall be permitted to use the facilities of the GBGC (except on certain specified days as mentioned in the Rules and Regulations or as may be notified from time to time by the Management). The Members shall be charged a fee, as specified in the Fee Structure or as notified by the Management from time to time, for the use of the facility of the GBGC by the guests of such Members.</p>
    <p>D) MEMBERSHIP CARD:</p>
    <ol style="list-style-type: none;">
      <li>i) Each Individual Member, Corporate, Nominee, Annual Member, their respective spouses and Eligible Children shall be issued Identity Card(s) by the Management which will be required to be produced on entry to the club premises and/or when using any club services/facilities.</li>
      <li>ii) Loss of the Identity Cards must be reported to the Management immediately</li>
      <li>iii) The Identity Cards of the concerned Eligible Children must be surrendered to the Management as soon as the child ceases to be eligible.</li>
      <li>iv) Whenever a Corporate ceases to be eligible under these Terms and Conditions or has been removed or replaced by the Corporate Member, the said nominee must surrender the Identity Cards including those of their spouses and Eligible Children to the Management immediately.</li>
      <li>v) In the event a spouse ceases to be a spouse by operation of law or otherwise, the Management must be immediately informed of the same and the Identity Card of the spouse must be surrendered to the management immediately by the Individual / Corporate / Nominee / Annual Member.</li>
    </ol>
  </li>

  <li>
    <p>USE OF THE GBGC AND OTHER FACILITIES:</p>
    <p>a) Entry of pets within the premises of the GBGC is strictly prohibited</p>
  </li>
</ol>

</body>
</html>

`;
const htmlContent5 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1 - Continued</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="14">
    <ul style="list-style-type: none;">
      <li>b) Smoking is permitted only in the designated areas as specified by the Management or as stated in the Rules & Regulations</li>
      <li>c) Possession of firearms, ammunitions and weapons of any kind whether licensed or not within GBGC premises is strictly prohibited.</li>
      <li>d) Food and beverages will be supplied by the GBGC at rates as fixed from time to time by the Management. Catering by outside agency within the GBGC premises is not permitted.</li>
      <li>e) Users of the GBGC are not permitted to give any monetary or non- monetary benefits to any staff member of the GBGC. A service charge will be added in the bills relating to food, soft beverages and other items.</li>
      <li>f) Children below 12 years of age will not be allowed entry in the GBGC unless accompanied by an adult Member or Guest of a Member admitted to the GBGC by the Member who himself is entitled to use the GBGC facilities.</li>
      <li>g) Individual Members or Corporate Nominees are requested to authorize their Eligible Children.</li>
      <li>h) The Management reserves the right to verify the identity of all persons entering the premises to ensure that only Members, their spouses, Eligible Children and guests of such Members are permitted.</li>
    </ul>

  <li>
    <p>DRESS CODE:</p>
    <ul style="list-style-type: none;padding-left: 0px;">
      <li>• Golf: <br/> <p style="margin-left: 15px;"> Golf courses require that men wear collared shirts, long pants made of cotton or a polyester blend, shorts with a pleated or flat front. These tops come in an array of colors and designs including button down, V-neck and zip-top in both short and long sleeves. Shorter pants should be knee length or longer. Skorts (a combination of a skirt and shorts) Collar T-shirt & Cotton Trouser, golf shoes with soft spikes are permitted.</p></li>
      <li>• Tennis: <br/> <p style="margin-left: 15px;">MEN: Tennis T-shirt, tennis shorts, predominately white socks & tennis shoes. WOMEN: tennis skirt and tennis T-shirt or tennis shorts, predominately white socks & tennis shoes.</p></li>
      <li>• Badminton: <br/> <p style="margin-left: 15px;">T-Shirts/Shorts/Skirts and Rubber sole shoes with sock. (Black sole are not allowed).</p></li>
      <li>• Swimming Pool: <br/> <p style="margin-left: 15px;">Swimming suit and caps are compulsory for ladies.</p></li>
      <li><p style="margin-left: 15px;"> a) It is expected that all the Users of the GBGC facilities shall follow the dress code as indicated in the Rules and Regulations of the GBGC.</p></li>
      <li><p style="margin-left: 15px;"> b) Dress code for the dining and lounge area may be casual. However such dress code may be made formal from time to time by the Management for special occasions and functions. Swimming, jogging, gym, athletic or other types of shorts are prohibited in areas other than the designated places as provided for in the Rules and Regulations.</p></li>
      <li><p style="margin-left: 15px;"> c) If any other facility will be added in future then the dress code will be informed by email/circular or it will be on the notice board.</p></li>
    </ul>
  </li>
</ol>

</body>
</html>

`;
const htmlContent6 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1 - Continued</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="15">
  <li>
    <p>GAME RULES:</p>
    <p>Detailed rules governing the playing of Golf, Tennis, Badminton, Gymnasium and use of the Swimming pool which are currently in force are notified from time to time to members.</p>
  </li>

  <li>
    <p>SUSPENSION OF PLAY:</p>
    <p>The Management reserves the right to suspend some or all of the Members from entering the golf course or any other designated area like Golf Course, Gym, Badminton Court, Tennis Court, Swimming pool and Change room of the GBGC or to play golf, in order to facilitate and conduct golf tournaments and events from time to time at all or some designated areas. No User shall have any right whatsoever to challenge the decision of the Management in this regard.</p>
  </li>
  
  <li>
    <p>CLUB STAFF:</p>
    <p>Member should not at any point engage in any argument with any Club Staff. Any issues with regard to staff should be informed to the Club Officer.</p>
  </li>
  
  <li>
    <p>COACHING FACILITY:</p>
    <p>The coaching facility on offer for all or some facilities at GBGC is run & managed under specific rules laid down by GBGC/Facilitator of coaching wherein no member will have any say on the procedure, technique, fees, timings, coaching area fixed from time to time by GBGC Management.</p>
  </li>
  
  <li>
    <p>PAYMENT FOR SERVICES & FACILITIES:</p>
    <p>A) Payments for all services and facilities including Pro-shop, Cafeteria, hiring of equipment (golf sets & golf carts), shuttle, range balls, caddies, guest charges etc., are required to be made by cash, approved credit card or cheque or by signing the invoice provided by the person designated by the Management.</p>
    <p>B) All Individual Members and Corporate Nominees are required to pay monthly/ annual fee as fixed from time to time irrespective of the usage of the GBGC. The monthly / annual fee is payable w.e.f. the date of the acceptance of the Membership by the Management and as specified in the Fee Structure. In the absence of any member not subscribing to any of the annual sporting plans the management will charge a minimum annual sports fee. All charges incurred by spouse, Eligible Children, guests will be debited to the account of the concerned Member.</p>
    <p>C) In the event of non-payment of dues by Members within 30 days from the due date of payment, interest @ 2% per month or part thereof will be charged and if the dues are not cleared within 30 days from the due date of payment, the said Member, the respective spouse and the Eligible Children may not be permitted to use the GBGC facilities and in addition the Membership is liable to be terminated at the sole discretion of the Management. The Management is not liable to issue individual notice to the defaulting Members in this regard.</p>
    <p>D) Unpaid CLUB DUES of Members will be made good from the membership Security Deposit. The shortfall in the Security Deposit should be cleared within 30 days failing which the club will cancel the Membership.</p>
  </li>
</ol>

</body>
</html>
`;
const htmlContent7 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1 - Continued</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="20">
    <ul style="list-style-type: none;padding-left: 0px;">
    <li>E) The Management of the GBGC shall provide golf carts and any related equipment that may be used subject to the availability, Rules and Regulations made in this regard. The use of these golf carts and related equipment will be at the user’s own risk and responsibility. Any damage / loss to the cart or parts thereof are to be made good by the user. Members will be solely responsible for settlement of bills on this account. In respect of the amounts payable by a Corporate Nominee the concerned nominee and the Corporate Member shall both be jointly and severally liable towards the GBGC.</li>
    <li>F) It will be the sole responsibility of members to update their details i.e. Mobile Number, Telephone Number, residential & office address & Email ID in case of any changes. Updating of Email ID is must since our Club monthly Invoice & Newsletters are sent to Email IDs as registered with us. In case we are not updated about the changes, it will result into accumulation of dues & would later on attract Late Fee charges which under any circumstances shall not be waived off once debited to the member. If any member does not receive Club Monthly Invoice or Circulars for more than a month then they need to contact Club office immediately.</li>
    </ul>
    <br/>
  <li>
    <p>ALTERATION IN FACILITIES:</p>
    <p>The Management shall have the sole discretion to make repairs, refurbishment, additions, modifications, reductions / elimination and temporary closure of all or some facilities of the GBGC. All Members must adhere the decision without any objection.</p>
  </li>

  <li>
    <p>LOSS, DAMAGES OR INJURIES:</p>
    <p>a) The use of the facilities of GBGC is entirely at the risk and responsibility of the individual Members or Corporate Nominee or Annual member and/or their respective guest, spouse or Eligible Children who use such facilities ("User"). The Management of the GBGC shall not be liable for any injury, damage or loss caused to the Users of the GBGC during the course of their visits to GBGC or due to use of the GBGC facilities.</p>
    <p>b) All the Users of GBGC are warned of high-speed balls that may cause grievous injuries / death or loss of property. Further, it shall be the duty / obligation of every Member to warn their respective spouses, guests, Eligible Children of such danger/risk before entering GBGC. All such Users shall be presumed to have understood the risks inherent and consented to their exposure to all the risks naturally incidental to their presence at the GBGC. All the Users shall enter the GBGC at their own risk and responsibility and the Management shall not be responsible for any consequences whatsoever. No one is allowed to venture into the golf course for purposes other than to play golf.</p>
    <p>c) Every User will be fully liable for any damage caused to the property of the GBGC or to the person or property of any employee of the GBGC or to any other User of the GBGC, resulting directly or indirectly from their action. Compensation for such damage will be fixed by the Management alone and shall be recoverable from the concerned Corporate Nominee or Individual Member.</p>
    <p>d) The Members of the GBGC agree to fully indemnify and hold harmless GEPL, its directors, officers and employees for any liability, loss or damage to GEPL from third parties resulting from the acts or omissions of such Members.</p>
    <p>e) Any action for indemnification by the Management is without prejudice to any other legal action that the Management may initiate in this regard.</p>
  </li>
</ol>

</body>
</html>

`;
const htmlContent8 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1 - Continued</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="22">
  <li>
    <p>USE BY NON-MEMBERS:</p>
    <p>The management shall have the absolute right to allow the use of Club facilities, including the golf course to any persons who are not members of the Club and without prejudice to the generality of that right to persons staying in the hotels or service apartments, or villas to be built in the vicinity of the golf course, their guests and family members and members of other Clubs having reciprocal arrangements with the Club and also persons attending functions organized on the premises of the Club.</p>
  </li>

  <li>
    <p>EFFECTS OF EVENTS BEYOND MANAGEMENT CONTROL:</p>
    <p>The Management shall not be responsible or liable for not performing any obligation or undertaking given to the Members, if such performance is prevented, delayed or hindered by an act of God, fire, flood, explosion, earthquake, warriot, terrorist act, sabotage, inability to procure or general shortage of energy, labour, equipment, facilities, material or supplies, action of labour unions, due to enactment of any law or issue of any directive by any Government or Public Authority or by a Competent Court or any other cause not within the control of the Management. The Management is further absolved from any liability towards any User of the GBGC arising from any act beyond the control of the Management of the GBGC.</p>
  </li>

  <li>
    <p>CONSTRUCTION, MODIFICATION, DEMOLITION OF STRUCTURES & BUILDINGS:</p>
    <p>GEPL as the lessee of the land utilized for the GBGC shall be free to alter any existing structure or building and to construct any building including hotels, villas, commercial plazas, etc., on the land or on the periphery of the GBGC with the permission of the appropriate authorities. Members/their nominees shall have no right to object, obstruct or create hindrance, dispute or raise any claims in respect thereof.</p>
  </li>

  <li>
    <p>COMPLIANCE BY NON-RESIDENT INDIANS AND FOREIGN CITIZEN MEMBERS:</p>
    <p>a) Members who are non-resident Indians, foreign nationals or expatriates shall be solely responsible for complying with the required formalities as laid down in Foreign Exchange Management Act 1999 or any statutory re-enactment or replacement, and Rules made there under and all other applicable laws for the time being in force governing the remittance of payments to the GBGC.</p>
    <p>b) The applicants and Members shall be solely responsible for violations if any, under the aforesaid laws and the GBGC accepts no responsibility in this regard. Such Members shall keep GEPL and the Management fully indemnified and hold GEPL harmless in his regard.</p>
    <p>c) In the event of the failure on the part of a person to comply with aforesaid laws and rules, the Membership is liable to be cancelled by the Management.</p>
    <p>d) The applicants and Members shall be responsible for providing to the Management necessary permissions/approvals as applicable, prescribed under aforesaid laws.</p>
  </li>

  <li>
    <p>RULES NOT EXHAUSTIVE:</p>
    <p>These rules are not exhaustive and the Management shall have the absolute right and sole discretion to add to or amend them from time to time and all such additions or amendments will be notified by displaying them only on the notice board of the club and shall become immediately binding on all the users of the Club.</p>
  </li>
</ol>

</body>
</html>

`;
const htmlContent9 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1 - Continued</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="27">
  <li>
    <p>IN THESE RULES, UNLESS THERE IS ANYTHING REPUGNANT IN THE SUBJECT OR CONTEXT:</p>
    <p style="margin-bottom: 0px;">a) Words importing the masculine genders shall be taken to include females: and</p>
    <p>b) Words in the singular shall include the plural and vice versa.</p>
  </li>

  <li>
    <p>NON-TRANSFER OF MEMBERSHIP:</p>
    <p>Individual or Annual Membership is non-transferable</p>
  </li>

  <li>
    <p>CESSATION OR TERMINATION OF MEMBERSHIP:</p>
    <p>i) In the event of any individual Member or any of his guests, spouse or children being found guilty of questionable behavior, misconduct or misuse of any of the facilities of the Club, the Management may terminate his membership and forfeit the whole or part of his interest free security deposit in accordance with the Schedule laid down under Rule 11 (B).</p>
    <p>ii) In the event of any individual/annual/corporate member/nominee or any of their guest, spouse or children are found guilty of questionable behavior, misconduct or misuse of any of the facilities of the Club, the Corporate Member may be required by the Management to withdraw himself and propose the name of another nominee acceptable to the Management.</p>
  </li>

  <li>
    <p>DEATH OF INDIVIDUAL/ANNUAL MEMBER:</p>
    <p>Upon death of an Individual/Annual member, the person nominated by the Member in the Application Form shall alone be entitled to get the refund of interest free Security Deposit after deducting all the outstanding dues of the deceased Individual/Annual Member. Alternatively, the nominee himself/herself can opt for Membership by paying the then prevailing Membership Fee.</p>
  </li>

  <li>
    <p>TERMS AND CONDITIONS NOT EXHAUSTIVE:</p>
    <p>Terms and Conditions or the Rules and Regulations framed there under are not exhaustive and the Management shall have the sole discretion to add, amend or delete them from time to time and all such additions, amendments will be notified by displaying them on the notice board of the GBGC / Monthly Club Circular/Emailer for the Members and shall become binding immediately on all the users of GBGC.</p>
  </li>

  <li>
    <p>INTERPRETATION OF TERMS AND CONDITIONS:</p>
    <p>In these rules, unless there is anything repugnant in the subject or context: Words importing the masculine gender shall be taken to include females; and Words in the singular shall include the plural, vice versa.</p>
  </li>

  <li>
    <p>ARBITRATION:</p>
    <p>It is agreed to and undertaken by the Members and/or their Nominees / Guests / Eligible Children hereto that any difference or dispute that may arise by and between them and the Management in the interpretation of these Terms and Conditions or other incidental matter arising out of the Terms and Conditions, shall be resolved as far as practicable through mutual negotiations failing which the matter would be referred to arbitration. Arbitration shall be conducted by a sole arbitrator appointed by the Management of the GBGC. The arbitration</p>
  </li>
</ol>

</body>
</html>
`;
const htmlContent10 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1 - Continued</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol start="34">
    <p>shall proceed in accordance with the Arbitration and Conciliation Act, 1996. The arbitrator shall give a reasoned
    award. The venue of the arbitration shall be Baroda.</p>
  <li>
    <p>JURISDICTION:</p>
    <p>This offer is strictly subject to courts of BARODA & GUJARAT HIGH COURT ONLY.</p>
  </li>
  <li style="list-style-type: none">
    <h6 style="margin-left: -21px;"><strong>DECLARATION</strong></h6>
    <p>I / We hereby declare that I / we are applying for the membership of GBGC after being fully satisfied about the quality & quantity of facilities and after going through the membership terms & conditions of the club being satisfied in all respects on the basis of my / our judgment.</p>
    <p>I /We have not been influenced by anything extraneous including any oral assurances given by any person connected with GBGC.</p>
    <p>I / We hereby agree to pay the annual/ monthly/ sports subscription fee as fixed by the management from time to time payable in advance or when requested, change of nominee fee in case of corporate membership, re-nomination fee as applicable, sports fees as may be applicable & prices of food and other consumables used by my family members, guests will be charged to my account.</p>
    <p>I / We understand that the terms & conditions as accepted by me / us may be amended from time to time at the sole discretion of the management and such amended rules shall become binding on me/ us.</p>
    <p>I / We accept that in the event of my/ our application being rejected all monies paid by me / us shall be refunded.</p>
    <p>I /We agree to be fully liable for the violation of the rules & regulations as stipulated therein.</p>
    <p>I / We have sought detailed explanation and clarification from the management in respect of this application and the management has readily provided all such explanation & clarification.</p>
    <p>I /We have signed this application, fully conscious of my/ our liabilities & obligations.</p>
    <p>I /We assure the management that in the event of total cancellation / abrogation of the rules & regulations I / we shall accept the decision of the management and shall not dispute or challenge such cancellation / abrogation.</p>
    <p>I /We am / are aware that the management of GBGC has absolute right to accept or reject my/ our application without assigning any reason and I /We agree that I /We shall not challenge the decision of the management.</p>
  </li>
  </ol>
</body>
</html>

`;
const htmlContent11 = 
`<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>TERMS & CONDITIONS Form No: 1 - Continued</title>
<style>
  body {
    font-family: Calibri;
    color: #222; /* Even darker font color */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
</head>
<body>
<ol  >
    <li style="list-style-type: none; padding-bottom: 300px">
        <p>All the aforesaid undertakings & commitments given by me/ us shall be equally binding on my/ our legal representatives & successors-in-interest and their legal representatives also.</p>
        <p>I /We also Undertake that I have no criminal record and not been jail for any offence. I /We also Undertake that I have not defaulted on any bank loan.</p>
        <p>I / We as a member of this club hereby fully agree to keep the club & the company fully indemnified & harmless in respect of the following: any injury(ies), accident(s), impairment(s), loss of life that may be caused to me / us, any other member, third party(ies), staff and employees working in the club, any person(s) inside / outside the periphery / boundary of the golf course, my family members, guests visitors which may be caused or occasioned during the use of the club facilities by me.</p>
        <p>I / We are fully knowledgeable as to the proper use of the facilities in the club as well as to my/ our own physical limitations, medical history etc.</p>
        <p>It is specifically understood and agreed by me that this declaration shall be binding on me or my heirs, successors, and assigns at all times during my membership of the club and use of the club facilities from time to time.</p>
    </li>
</ol>

</body>
</html>
`;


const formHtmlList = [
    htmlContent1, htmlContent2, htmlContent3, htmlContent4, htmlContent5, htmlContent6, htmlContent7, htmlContent8, htmlContent9, htmlContent10, htmlContent11
]


export default formHtmlList;