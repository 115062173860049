import { getAuthState } from "../../assets/scripts/login-util";
import { axiosGet, axiosPost } from "./axiosFunctions";


const loginUrl                                  = '/api/users/login';
const loginWithOTPUrl                           = '/api/users/loginWithOTP';
const logoutUrl                                 = '/api/users/logout';
const verifyMobileNumberUrl                     = '/api/users/verifyMobileNumber';
const verifyEmailUrl                            = '/api/users/verifyEmail';
const verifyRegistrationOTPUrl                  = '/api/users/verifyRegistrationOTP';
const forgotPasswordUrl                         = '/api/users/forgotPassword';
const sendRegistrationOTPUrl                    = '/api/users/sendRegistrationOTP';
const changePasswordUrl                         = '/api/users/changePassword';
const registerUserUrl                           = '/api/users/registerUser';


export const login = async (userName, password, playerID, deviceName, isRemember) => {   
    const response = await axiosGet(loginUrl, { headers: '', params: {userName, password, playerID, deviceName, isRemember}});
    return response;
}

export const loginWithOTP = async ( userName, sessionid, code, playerID, deviceName, isRemember) => {   
    const response = await axiosGet(loginWithOTPUrl, { headers: '', params: { userName, sessionid, code, playerID, deviceName, isRemember}});
    return response;
}

export const logout = async () => {
    const token = getAuthState('token');
    if (!token) setTimeout(()=> { return window.open('/', '_self'); }, 2000);
    
    const response = await axiosGet(logoutUrl, {
        headers: { auth_token: `bearer ${token}` }
    });
    return response;
}

export const verifyMobileNumber = async (mobileNumber) => {   
    const response = await axiosGet(verifyMobileNumberUrl, { headers: '', params: {mobileNumber}});
    return response;
}

export const verifyEmail = async (userid, email) => {   
    const response = await axiosGet(verifyEmailUrl, { headers: '', params: {userid, email}});
    return response;
}

export const verifyRegistrationOTP = async (userid, sessionid, code, playerID, deviceName) => {   
    const response = await axiosGet(verifyRegistrationOTPUrl, { headers: '', params: {userid, sessionid, code, playerID, deviceName}});
    return response;
}

export const forgotPassword = async (mobile) => {   
    const response = await axiosGet(forgotPasswordUrl, { headers: '', params: {mobile}});
    return response;
}

export const sendRegistrationOTP = async (mobile) => {   
    const response = await axiosGet(sendRegistrationOTPUrl, { headers: '', params: {mobile}});
    return response;
}

export const changePassword = async (userid, password, sessionid, code) => {   
    const response = await axiosGet(changePasswordUrl, { headers: '', params: {userid, password, sessionid, code}});
    return response;
}

export const registerUser = async (user) => {    
    const response = await axiosPost(registerUserUrl, { user }, { headers: ''});
    return response;
}