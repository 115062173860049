import { useContext } from "react";
import { signatureContext } from "../../context/context";
import './membershipForm.css';

const FormFooter = (props) => {

    const signatureImg = useContext(signatureContext)
    return(
        <>
            <footer className="simple-footer">
                <hr style={{borderTop: '1px solid #c7bfbf'}}/>
                <div className="footer-content">
                    {(!!signatureImg) ? (
                        <div className="avatar__holder"
                            style={{
                                backgroundImage: typeof signatureImg === 'object' && signatureImg instanceof Blob
                                    ? `url(${URL.createObjectURL(signatureImg)})`
                                    : `url(${signatureImg})`,
                                width: "130px",
                                height: "30px",
                                marginLeft: '23px',
                                backgroundSize: "cover",
                                backgroundPosition: "center"
                            }}
                        ></div>
                    ) : (
                        <div style={{ width: "130px", height: "30px"}}></div>
                    )}                    
                    <h6 className="margin-20 padding-b20 margin-t10">Signature of Applicant</h6>
                    <span className="page-number">Page: {props.num}</span>
                </div>
            </footer>
        </>
    )
};

export default FormFooter;